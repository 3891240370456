import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { logger } from '@core/misc/misc.utils';
import { SVGIcon, SVG_ICONS } from '@data/svg-icons.data';

@Injectable()
export class SvgIconsService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public init(): Promise<boolean> {
    return new Promise((resolve) => {
      logger?.groupCollapsed('🏞 init icons');
      const iconNames = Object.keys(SVG_ICONS);
      for (let index = 0; index < iconNames.length; index++) {
        const icon: SVGIcon = iconNames[index] as SVGIcon;
        this.matIconRegistry.addSvgIcon(
          icon,
          this.domSanitizer.bypassSecurityTrustResourceUrl(`${this.document.baseURI}assets/svg/${SVG_ICONS[icon]}.svg`)
        );
        logger?.info(`${icon} => ${SVG_ICONS[icon]}.svg`);
      }
      logger?.groupEnd();
      resolve(true);
    });
  }
}
