import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { formatISO } from 'date-fns';

//changes format dates as backend required
Date.prototype.toISOString = function toISOString() {
  return formatISO(this).match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)![0];
};

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
